import { ROLES_CODES } from './../constants/roles'
import type { Referral } from '~/types/auth'
import type { Role } from '~/types/permissions'

export const isReferral = (user: any): user is Referral => 'deals_count' in user

// TODO: add TS support for these guards
export const isPartnerManager = (role?: Role) => role?.code === ROLES_CODES.PARTNER_MANAGER

export const isPartner = (role?: Role) => role?.code === ROLES_CODES.PARTNER

export const isSuperAdmin = (role?: Role) => role?.code === ROLES_CODES.SUPER_ADMIN

export const isCEO = (role?: Role) => role?.code === ROLES_CODES.CEO

export const isGuest = (role?: Role) => role?.code === ROLES_CODES.GUEST

export const isThriveStateDomain = (domain: string | undefined) => Boolean(domain?.includes('thrivestate'))
